.pagination {
  text-align: center;
  margin-bottom: 20px;
}

.page-prev {
  display: inline-block;
  background: url("../img/arrow_left.png") no-repeat center center;
  background-size: contain;
  height: 15px;
  width: 15px;
}

.page-next {
  background: url("../img/arrow_right.png") no-repeat center center;
  background-size: contain;
  display: inline-block;
  height: 15px;
  width: 15px;
}

.page-numbers {
  display: inline-block;
  padding: 7px 10px;
  margin: 0 1px;
  @include font-size(2);
}
