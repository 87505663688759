.container {
    padding: 0;
}

.kontakt {
    padding: 0;
    padding-top: 3%;
    padding-bottom: 3%;
}

.mapka {
    display: block;
    padding: 0;
    padding-top: 3%;
    padding-bottom: 3%;
}

.mapka2 {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .kontakt {
        padding-top: 5%;
        padding-left: 5%;
        text-align: left;
    }

    .mapka {
        display: none;
    }

    .mapka2 {
        padding-top: 0;
        display: block;
    }
}

@media only screen and (max-width: 991px) {
    .kontakt {
        padding-top: 5%;
        padding-left: 5%;
        text-align: left;
    }

    .mapka {
        display: none;
    }

    .mapka2 {
        padding-top: 70;
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    h1 {
        text-align: center;
    }

    .kontakt {
        padding-top: 5%;
        padding-left: 5%;
        text-align: center;
    }

    .mapka {
        display: none;
    }

    .mapka2 {
        padding-top: 10%;
        display: block;
        text-align: center;
    }
}

@media only screen and (max-width: 320px) {
    .mapka2 {
        margin-left: 0;
        display: block;
        text-align: center;
    }

}