// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.circle {
    @include circle(15px);
}

body {
    position: relative;
    overflow-x: hidden !important;
}

.wp-block-image img {
    max-width: 100%;
    height: auto;
}

.conteiner {
    z-index: 1;
}

.blackpink {
    color: $blackpink;
}

.black {
    color: $black;
}

.pink {
    color: $pink;
}

.half-container {
    max-width: 570px;
}

.navbar {
    margin: 0;
    padding: 0;
}

.col-6 {
    padding-bottom: 0px;
}

.topek {
    position: relative;

    h1 {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        color: $pink;
        font-size: 3.8em;
        text-transform: uppercase;
        width: 570px;
        text-align: right;
        margin-right: 1%;
        font-weight: normal;
    }

    .obrazek {
        position: relative;
        background: url("../../assets/img/1_18.jpg");
        background-size: 110%;
        width: 100%;
        height: 632px;
        ;
        background-position: 0px 0;
        background-repeat: no-repeat;

    }

    .obrazektop {
        position: relative;
        height: 500px;
    }
}

.slider {
    display: block;
    padding: 0;

    .row {
        height: 500px;
    }

    .sliderobrazek {
        background: url("../../assets/img/1_21.jpg");
        background-repeat: no-repeat;
        position: relative;
        padding: 0;
        width: 100%;
        display: block;
        height: 300px;
        left: 2%;
    }

    .tittleslider {
        width: 100%;
        text-transform: uppercase;
        background-color: $blackpink;
        color: $white;
        font-size: 24px;
        padding: 0;
        margin: 0;
        display: block;
        text-align: center;
        padding: 13%;
    }

    .menuslider {
        margin-top: 30px;

    }

    .sliderrezerwacje {
        text-transform: uppercase;
        background-color: $blackpink;
        padding: 20px;
        text-align: center;
        display: block;
        font-size: 24px;
        color: $white;
        margin-top: 16%;
    }

    .sliderrezerwacje:after {
        content: '';
        position: absolute;
        float: left;
        width: 100%;
        height: 35px;
        background: url("../../assets/img/1_03.png") no-repeat 0 0;
        background-position: 15px -6px;
    }

    .strzalka {
        position: relative;
        width: 630px;
        font-size: 24px;
        text-transform: uppercase;
        color: $pink;
        display: block;
        margin-left: 30%;
        height: 30px;
        bottom: 13%;
    }

    .strzalka:after {
        content: '';
        position: absolute;
        float: left;
        width: 100%;
        height: 30px;
        background: url("../../assets/img/1_14.png") no-repeat 0 0;
        background-position: 30px 5px;
    }
}

.owl-carousel {
    position: relative;
    height: 50%;
    z-index: 1;
}

.owl-stage-outer {
    height: 80%;
    height: 174px;
    left: 2%;
}

.owl-stage {
    position: absolute;
}

.owl-carousel .owl-nav {
    background-color: $pink;
    color: $white;
    z-index: 0;
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    right: 0;
    top: -41%;

}

.owl-carousel .owl-nav button {
    background-color: $pink;
    border: 0px solid !important;
    border-radius: none;
    box-shadow: none;
    background-color: transparent !important;

    &:hover {
        background-color: transparent !important;
    }

    &:focus {
        background-color: transparent !important;
    }
}

.owl-carousel .owl-nav button span {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 2px solid $white;
    border-left: 2px solid $white;
    position: absolute;
    top: -15%;
    height: 20px;
    width: 20px;
}

.owl-dots {
    position: relative;
    border-color: $pink;
    top: -112%;
    right: 0;
    transform: translateY(-100%) translateX(-50%);
    left: 80%;
    width: 21%;
    display: flex !important;
    flex-flow: row nowrap !important;
    justify-content: center !important;

    .owl-dot {

        &:focus {
            outline: none;
        }

        &.active span {
            background-color: $blackpink !important;
        }
    }
}

.owl-dot span {
    background: $white !important;

    &:hover {
        background-color: $blackpink !important;
    }
}

.sliderrezerwacjeres {
    display: none;
}

.zabiegi {
    .container-fluid {
        width: 100%;
    }

    .menuzabiegi {
        margin-top: 30px;
        padding: 0px;
        margin: 0 px;
    }

    .obrazzabiegow {
        padding: 0px;
        position: relative;

    }

    .obrazzabiegow img {
        width: 100%;
        padding: 0px;
        margin: 0px;
        height: 100%;
    }

    .tytulyzabiegow {
        position: absolute;
        text-transform: uppercase;
        text-align: center;
        font-size: 2.8em;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        padding: 30px 0px;
        width: 60%;
    }

    .obrazzabiegow:nth-child(odd) {
        .tytulyzabiegow {
            right: 0px
        }
    }

    .obrazzabiegow:nth-child(even) {
        .tytulyzabiegow {
            left: 0px
        }
    }

    .obrazzabiegow:nth-child(1n+0) {
        .tytulyzabiegow {
            background: $pinkopacyty;
            color: $white;
        }
    }

    .obrazzabiegow:nth-child(3n+1) {
        .tytulyzabiegow {
            background: $whiteopacyty;
            color: $pink;
        }
    }
}

.opis {
    .opispotrzebgora {
        display: block;
        text-align: center;
        margin-top: 30px;
        text-transform: uppercase;
        font-size: 32px;
        color: $pink;

    }

    .opislewy {
        display: block;
        text-align: justify;
        font-size: 14px;
        margin: 30px 20px;
        float: right;
        max-width: 60%;
        padding-left: 3%;
    }

    .opisprawy {
        display: block;
        text-align: justify;
        font-size: 14px;
        margin: 30px 20px;
        max-width: 60%;
        padding-left: 3%;
    }

    .podtekstpotrzeblewy {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 29px;
        font-family: "Montserrat";
        font-weight: normal;
        color: $pink;
        margin: 30px 20px;
        float: right;
        max-width: 60%;
        padding-left: 3%;
    }

    .podtekstpotrzebprawy {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 29px;
        font-family: "Montserrat";
        font-weight: normal;
        color: $pink;
        margin: 30px 20px;
        max-width: 60%;
        padding-left: 3%;
    }
}

.zapisy {
    .obrazzapisow {
        position: relative;
        width: 100%;
        padding: 0px;
        margin: 0px;
        height: 100%;
    }

    .obrazzapisow img {
        display: block;
        width: 100%;
    }

    .obrazzapisowtext {
        position: absolute;
        text-transform: uppercase;
        text-align: center;
        font-size: 2.8em;
        display: block;
        top: 50%;
        transform: translateY(-50%);
        background-color: $whiteopacyty;
        color: $pink;
        width: 100%;
    }

    .obrazzapisowikona {
        position: relative;
        height: 150px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: left;
        padding: 0;
    }

    .obrazzapisowikona:after {
        content: '';
        position: absolute;
        width: 200px;
        right: 217px;
        height: 150px;
        background-size: auto;
        background: url("../../assets/img/1_06.png") no-repeat 0 0;
    }
}

@media only screen and (max-width: 1800px) {
    .topek h1 {
        top: 40%;
    }

    .zapisy .obrazzapisowikona {
        padding-left: 101px;
    }

    .zapisy .obrazzapisowikona::after {
        left: 75%;
    }
}

@media only screen and (max-width: 1600px) {
    .topek h1 {
        top: 32%;
        font-size: 3.0em;
        margin-right: 5%;
    }

    .topek .obrazek {
        background-size: 110%;
        width: 100%;
        height: 545px;
    }
}

@media only screen and (max-width: 1400px) {
    .topek h1 {
        top: 27%;
        font-size: 3.0em;
        margin-right: 7%;
    }

    .topek .obrazek {
        background-size: 110%;
        width: 100%;
        height: 470px;
    }

    .opis .opisprawy {
        max-width: 63%;
        padding-left: 4%;
    }

    .opis .podtekstpotrzebprawy {
        max-width: 63%;
        padding-left: 4%;
    }

}

@media only screen and (max-width: 1200px) {
    .zabiegi .tytulyzabiegow {
        font-size: 1.8em;
    }

    .opis .opisprawy {
        max-width: 64%;
        padding-left: 5%;
    }

    .opis .podtekstpotrzebprawy {
        max-width: 64%;
        padding-left: 5%;
    }
}

@media only screen and (max-width: 1199px) {
    .topek .obrazek {
        background-size: 110%;
        width: 100%;
        height: 385px;
    }

    .topek h1 {
        top: 25%;
        font-size: 2.5em;
        margin-right: 5%;
        background-size: 100%;
    }

    .owl-carousel .owl-nav {
        top: -49%;
        right: -19px;
    }

    .owl-dots {
        width: 10%;
        top: -133%;
        left: 82%;
    }

    .slider {
        left: 2%;
    }

    .slider .sliderobrazek {
        left: 2%;
    }

    .owl-carousel .owl-stage-outer {
        left: 2%;
        height: 210px;
    }

    .zabiegi .tytulyzabiegow {
        font-size: 1.8em;
    }

    .slider .strzalka {
        margin-left: 20%;
    }

    .zapisy .obrazzapisowikona {
        justify-content: left;
        left: 15%;
    }

    .zapisy .obrazzapisowikona::after {
        right: 295px;
    }

    .opis .opislewy {
        float: none;
        max-width: 100%;
    }

    .opis .opisprawy {
        float: none;
        padding-left: 3%;
        max-width: 100%;
    }

    .opis .podtekstpotrzeblewy {
        float: none;
        max-width: 100%;
    }

    .opis .podtekstpotrzebprawy {
        float: none;
        padding-left: 3%;
        max-width: 100%;
    }

    .slider .sliderrezerwacje {
        margin-top: 23%;
    }

    .zapisy .obrazzapisowtext {
        font-size: 2.0em;
    }

    .zapisy .obrazzapisowikona {
        padding-left: 101px;
        left: 0;
    }

    .zapisy .obrazzapisowikona::after {
        left: 60%;
    }


}

@media only screen and (max-width: 991px) {
    .topek .obrazek {
        background-size: 110%;
        width: 100%;
        height: 173px;
    }

    .topek h1 {
        top: 17%;
        font-size: 2.0em;
        margin-right: 2%;
        background-size: 100%;
    }

    .slider .menuslider {
        height: 348px;
        margin-top: 0;
    }

    .slider .tittleslider {
        padding: 3%;
    }

    .slider .sliderobrazek {
        left: 1%;
    }

    .slider {
        height: 415px;
        left: 1.5%;
        width: 101%;
        z-index: -1;
    }

    .slider .sliderrezerwacje {
        display: none;
        margin-top: 27%;
        padding-right: 6%;
    }

    .sliderrezerwacjeres {
        text-transform: uppercase;
        background-color: $blackpink;
        padding: 20px;
        text-align: center;
        display: block;
        font-size: 24px;
        color: $white;
        padding-right: 6%;
    }

    .sliderrezerwacjeres:after {
        content: '';
        position: absolute;
        float: left;
        width: 100%;
        height: 35px;
        background: url("../../assets/img/1_03.png") no-repeat 0 0;
        background-position: 15px -6px;
    }

    .slider .sliderobrazek {
        display: none;
    }

    .slider .strzalka {
        display: none;
    }

    .owl-carousel {
        height: 70%;
    }

    .owl-carousel .owl-nav {
        top: -85%;
        right: -15px;
    }

    .owl-dots {
        width: 20%;
        top: -145%;
        left: 51%;
    }

    .owl-carousel .owl-stage-outer {
        left: 0%;
        top: -80%;
    }

    .item {
        text-align: center;
        padding-left: 5%;
        padding-right: 5%;
    }

    .zabiegi .menuzabiegi {
        margin-top: 0;
        padding: 0px;
        margin: 0 px;
    }

    .zabiegi .obrazzabiegow {
        z-index: 5;
    }

    .zabiegi .obrazzabiegow img {
        height: 101%;
    }

    .zapisy .obrazzapisowikona::after {
        background-size: 76%;
        top: 20%;
        background-size: 57%;
        left: 62%;
    }

    .zapisy .obrazzapisowikona {
        padding-left: 0;
        left: 0;
        left: 122px;
    }

    .zapisy .obrazzapisowtext {
        font-size: 1.5em;
    }

    .zabiegi {
        .obrazzabiegow:nth-child(1n+2) {
            .tytulyzabiegow {
                background: $pinkopacyty;
                color: $white;
            }
        }

        .obrazzabiegow:nth-child(2n+1) {
            .tytulyzabiegow {
                background: $whiteopacyty;
                color: $pink;
            }
        }
    }
}

@media only screen and (max-width: 920px) {
    .topek .obrazek {
        height: 300px;
    }
}

@media only screen and (max-width: 820px) {
    .topek .obrazek {
        height: 280px;
    }
}

@media only screen and (max-width: 768px) {
    .topek .obrazek {
        height: 255px;
    }

    .topek h1 {
        top: 15%;
        font-size: 1.8em;
        margin-right: 2%;
        background-size: 100%;
    }

    .owl-carousel {
        height: 71%;
    }

    .sliderrezerwacjeres {
        padding-right: 10%;
    }

    .zapisy .obrazzapisowikona {
        padding-left: 0px;
        left: 0;
        left: 57px;
    }

    .zapisy .obrazzapisowikona::after {
        background-size: 57%;
        left: none;
        right: 12%;
        top: 20%;
    }

}

@media only screen and (max-width: 700px) {
    .topek .obrazek {
        height: 220px;
    }

    .owl-carousel {
        height: 72%;
    }
}

@media only screen and (max-width: 600px) {
    .topek .obrazek {
        height: 205px;
    }

    .topek h1 {
        top: 10%;
        font-size: 1.4em;
    }

    .slider .tittleslider {
        font-size: 18px;
    }

    .slider .tittleslider {
        padding: 4%;
    }

    .sliderrezerwacjeres {
        padding: 23px;
        font-size: 18px;
        padding-right: 10%;
    }

    .item {
        font-size: 14px;
    }

    .zapisy .obrazzapisowikona {
        font-size: 0.6em;
        left: 0;
        padding-left: 20%;
    }

    .zapisy .obrazzapisowikona::after {
        left: 65%;
        background-size: 35%;
        top: 30%;
    }
}

@media only screen and (max-width: 576px) {
    .topek .obrazek {
        height: 183px;
    }

    .slider .tittleslider {
        width: 103%;
    }

    .owl-carousel .owl-nav {
        margin: 0;
        width: 100%;
        top: -159%;
        padding: 4px;
    }

    .owl-carousel .owl-nav button span {
        top: 15%;
    }

    .owl-dots {
        width: 20%;
        top: -161%;
        left: 52%;
    }

    .owl-carousel .owl-stage-outer {
        top: -88%;
    }
}

@media only screen and (max-width: 500px) {
    .topek .obrazek {
        height: 165px;
    }

    .topek h1 {
        top: 7%;
        font-size: 1.0em;
    }

    .owl-carousel .owl-nav {
        top: -160%;
    }

    .owl-dots {
        width: 25%;
        top: -163%;
        left: 53%;
    }

    .item {
        font-size: 13px;
        margin-left: 6%;
    }

    .zapisy .obrazzapisowikona {
        font-size: 0.6em;
        left: 0;
        padding-left: 10%;
    }

    .zapisy .obrazzapisowikona::after {
        left: 73%;
        background-size: 35%;
        top: 30%;
    }
}

@media only screen and (max-width: 450px) {
    .topek .obrazek {
        height: 145px;
    }
}

@media only screen and (max-width: 400px) {
    .topek .obrazek {
        height: 132px;
    }

    .topek h1 {
        top: 7%;
        font-size: 0.8em;
    }

    .owl-dots {
        left: 54%;
    }

    .owl-carousel .owl-stage-outer {
        top: -87%;
        font-size: 12px;
        height: 217px;
    }

    .slider .tittleslider {
        padding: 6%;
    }

    .item {
        font-size: 12px;
    }

    .sliderrezerwacjeres {
        padding-right: 17%;
    }

    .zabiegi .tytulyzabiegow {
        font-size: 1.2em;
    }

    .zapisy .obrazzapisowikona {
        font-size: 0.6em;
        left: 0;
        padding-left: 5%;
    }

    .zapisy .obrazzapisowikona::after {
        left: 75%;
        background-size: 24%;
        top: 36%;
    }
}

@media only screen and (max-width: 360px) {
    .topek .obrazek {
        height: 115px;
    }
}

@media only screen and (max-width: 320px) {
    .topek .obrazek {
        height: 115px;
    }

    .topek h1 {
        top: 7%;
        font-size: 0.8em;
    }

    .zabiegi .tytulyzabiegow {
        font-size: 1.2em;
    }

    .obrazzapisowikona {
        font-size: 0.6em;
    }

    .zapisy .obrazzapisowikona {
        padding-left: 8%;
    }

    .zapisy .obrazzapisowikona::after {
        background-size: 24%;
        right: 8%;
        top: 37%;
        left: 78%;
    }
}