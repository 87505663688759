footer {
  padding: 0;
  top: 0;
  display: block;
}
h2{
  text-align: left;
  margin-top: 60px;
  padding: 0 15px;
  color: $white
;}
.footer__credentials {
  p {
    margin: 0 0 5px;
    transition: $transition;
  }
  a:hover {
    color: $grey-mid;
  }
}
.footer__credentials1{
  color: $white;
  font-size: 16px;
  font-family: "Montserrat";
  letter-spacing: 1px;
  line-height: 29px;
  font-weight: 300;
  flex-flow: column;
}
.footer__credentials2{
  margin-left: 30px;
}

.footer1{
  background-color: $pink;
  position: relative;
  padding-bottom: 10%;
}
.footer1 .formularz {
  margin-right: 0;
  padding: 0;
  max-width: 65%;
}
.footer__credentials p{
  display: flex;
  justify-content: right;
}
.col-12{
  margin-right: 0px;
  padding-right: 0px;
}
.col-6 {
padding-bottom: 130px;
}
.writeus:before{
  content: "";
float: left;
width: 52px;
height: 45px;
margin: -14px 5px 0 0;
background: url(../img/1_03.png) no-repeat 0 0;
background-position: 0px -279px;
}
.callus:before{
  content: "";
float: left;
width: 52px;
height: 45px;
margin: -14px 5px 0 0;
background: url(../img/1_03.png) no-repeat 0 0;
background-position: 0px -209px;
}
.footer2{
  background-color: $white;
  padding-bottom: 10%;
}
.footer2{
  background-color: $white;
  color: $pink;
  text-decoration-color: $pink;
}
.footer2 h2{
color: $pink;
font-size: 27px;
letter-spacing: 1px;
line-height: 29px;
font-family: "Montserrat";
font-weight: 300;
padding: 0;
}
.footer2 p{
  color: black;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 29px;
  font-family: "Montserrat";
  font-weight: 300;
  text-align: left;
  }
.podtekst{
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 29px;
  font-family: "Montserrat";
  font-weight: 300;
  color: $pink;
  text-align: left;
}
.stopka{
  position: relative;
  font-size: 16px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%); 
  line-height: 55px;
  color: #000000;
  font-family: "Arial";
  text-align: center;
  opacity: 0.5;
  margin-top: -5%;
}
.footer2 .kontaktstopki{
max-width: 65%;
margin: 0;
}


// formularz

.nf-form-content .list-select-wrap 
.nf-field-element > div, 
.nf-form-content input:not([type="button"]), 
.nf-form-content textarea {

background: none!important;
color: #fbf9f9!important;
}
.nf-form-content textarea {
background: #c95ea6 !important;
}
.nf-field-label{
width: 350px;
}
#nf-field-1-wrap > div:nth-child(1) {
padding-left: 40px;
}
#nf-field-2-wrap > div:nth-child(1) {
padding-left: 40px;
}
#nf-field-3-wrap > div:nth-child(1) {
padding-left: 40px;
}
#nf-field-17-wrap > div:nth-child(1) {
padding-left: 40px;
}
.label-above .field-wrap, .label-below .field-wrap {
flex-flow: row-reverse!important;
}
.nf-pass.field-wrap .nf-field-element::after {
  top: -10px;
  right: -3px;
}
.nf-error .nf-error-msg{
color: none!important;
}
.nf-error .ninja-forms-field{

border: 1px solid #fff!important;
}
.nf-form-content input:not([type="button"]){
border: none!important;
border-bottom: 1px solid #c4c4c4!important;
}
.nf-form-content input[type="button"]{
background: #fff!important;
color: #bb358e!important;
}
.nf-form-content input[type="button"]:hover{
background: #fff!important;
color: #bb358e!important;
}
.nf-error .nf-error-msg {
    color: #a3e411!important;
}
.nf-error-msg{
color: #a3e411!important;
}
.field-wrap input[type="button"]{
width: 120px!important;
}
.field-wrap > div input{
width: 100%!important;
}
.field-wrap textarea{
width: 100%!important;

}
.nf-error.field-wrap .nf-field-element::after {
top: -1px!important;
height: 30px!important;
width: 30px!important;
line-height: 35px!important;
}
.nf-form-content label {
    font-weight: 400!important;
    font-size: 14px!important;
}
.footer__credentials1 {
    font-size: 11px!important;
}
.nf-field{
margin: 0!important;

}
.nf-field-container {
margin: 0px!important;
}
.nf-form-content input.ninja-forms-field{
height: 25px!important;
}
.nf-field-label{
margin: 0px!important;
}
.nf-form-content input.ninja-forms-field {
height: 55px !important;

}
.nf-form-fields-required{
display: none!important;

}
#nf-field-1 {
padding: 0;
height: 30px !important;
}
#nf-label-field-1 {
vertical-align: bottom;
}
#nf-field-2 {
padding: 0;
height: 30px !important;
}
#nf-label-field-2 {
vertical-align: bottom;
}
#nf-field-17 {
padding: 0;
height: 30px !important;
margin-bottom: 10% !important;
}
#nf-label-field-17 {
vertical-align: bottom;
}
#nf-field-3 {
  resize: none;
height: 130px;
top: 10%!important;
}
#nf-label-field-3 {
vertical-align: top;
}
#nf-field-4 {
margin-top: 20px;
margin-right: 65px;
}
#nf-field-4-wrap > div:nth-child(1) {
  display: none;
  }
#nf-field-4-container {
top: -80px;
right: 0;
text-align: right;
}
.nf-form-content label{
line-height: 10px!important;
}

@media only screen and (max-width: 1800px){
  .szerokoscstopki{
    width: 106%;
  }
  .footer1 .formularz{
  max-width: 76%;
  }
  .footer__credentials2 {
    margin-left: 15%;
}
.nazwacontakt {
  margin-left: 20px;
  margin-top: 10px;
  width: 103%;
}
.nf-form-layout > form:nth-child(1) {
  width: 91%;
  }
  .footer2 h2 {
    font-size: 23px;
  }
  .footer2 p {
    margin-top: 32px;
   }
}
@media only screen and (max-width: 1600px){
  .footer1 .formularz{
  max-width: 87%;
  }
  .footer__credentials2 {
    margin-left: 20%;
}
.footer2 h2 {
  font-size: 19px;
}

}
@media only screen and (max-width: 1400px){
  .footer1 .formularz{
  max-width: none;
  }
  .footer2 .kontaktstopki {
    max-width: 85%;
    margin: 0;
}
.footer__credentials2 {
  margin-left: 25%;
}
.nf-form-layout > form:nth-child(1) {
  width: 100%;
  }
}
@media only screen and (max-width: 1200px){
  .footer{
width: 104%;
}
form{
position: relative;
text-align: left;
justify-content: center;
display: flex;
  }
  .writeus{
  position: relative;
  text-align: center;
  left: -13%;
  }
  .writeus:before{
    position: absolute;
    right: 70%;
  }
  .footer__credentials1 p {
    position: absolute;
    left: 370px;
    }
  .footer2 p {
    text-align: center;
  }
}
@media only screen and (max-width: 1199px){
  form{
    width: 100%;
  }
  .writeus:before{
    right: 56%;
  }
  .writeus{
    left: 26px;
    width: 100%;
    }
    .footer__credentials1 p {
      position: absolute;
      left: 60%;
      }
      .footer__credentials2 {
      margin: 0;
      }
      .callus {
        text-align: center;
        }
        .podtekst {
          text-align: center;
          }
          .callus::before {
      position: absolute;
      left: 28%;
          }
    .footer2 .kontaktstopki {
            max-width: 100%;
          }
          .field-wrap {
        display:block!important;
          }
          #nf-field-1-container {
            text-align: center;
            }
            #nf-label-field-1 {
              vertical-align: initial;
          } 
          #nf-field-1-wrap > div:nth-child(1) {
            padding-left: 0px;
        }
            #nf-field-2-container {
              text-align: center;
              }
              #nf-label-field-2 {
                vertical-align: initial;
            } 
            #nf-field-2-wrap > div:nth-child(1) {
              padding-left: 0px;
          }
              #nf-field-3-container {
                text-align: center;
                }
                #nf-label-field-3 {
                  vertical-align: initial;
              } 
              #nf-field-3-wrap > div:nth-child(1) {
                padding-left: 0px;
            }
                #nf-field-17-container {
                  text-align: center;
                  }
                  #nf-label-field-16 {
                    vertical-align: initial;
                } 
                #nf-field-17-wrap > div:nth-child(1) {
                  padding-left: 0px;
              }
                  #nf-field-4 {
                    margin-top: 100px;
                    margin-right: 100px;
                } 
                #nf-label-field-1 {
                  vertical-align: initial;
              }  
              #nf-field-4 {

                margin-right: 115px;
            
            }
              
}
@media only screen and (max-width: 992px){
  form {
    position: relative;
    }
    .footer__credentials1 p {
      position: absolute;
      bottom: -13px;
      left: 62%;
      width: 183px;
      }
      .writeus::before{
        right: 65%;
      }
      .callus::before{
        left: 22%;
      }
     
}
@media only screen and (max-width: 991px){
  .footer2 p {
    width: 90%;
}

}
@media only screen and (max-width: 800px){
  .nf-form-layout > form:nth-child(1) {
    width: 40%;
    }
  .nf-form-content {
     width: 160%;
    }
    #nf-field-4 {
      margin-left: 100px;
      }
}
@media only screen and (max-width: 768px){
    .footer__credentials1 p {
      left: 385px;
  }
  .footer1{
    padding-bottom: 30%;
  }
  .writeus{
    left: 4%;
    width: 100%;
  }
  .writeus::before{
    width: 66px;
    right: 60%;
  }
  form{
    width: 100%;
  }
    .footer__credentials1 {
      font-size: 10px;
      padding-left: 0%;
  
  }
  .footer__credentials1 p{
    left: 42%;
    bottom: 0px;
    top: 100%;
  }
  .nazwacontakt{
    margin: 0;
  }
  .footer2 .kontaktstopki {
    max-width: 100%;
    margin: 0;
  }
  .footer__credentials2 {
    left: 0%;
    margin: 0;
    padding-left: 0%;
  }
  .footer2 h2 {
    font-size: 15px;
    padding-right:0;
  }
  .footer2 p {
    width: 90%;
    text-align: center;
  }
  .callus::before {
    margin-left: 0%;
    }
  .callus {
    width: 100%;
      }
    .nazwacontakt {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          }
}
.nf-form-layout > form:nth-child(1) {
  width: 109%;
  }
  .nf-form-content {
    width: 100%;
    }
    

@media only screen and (max-width: 600px){
  form{
    width: 100%;
  }

.footer__credentials2 {
      padding: 0;
    }
.footer2 p {
      width: 95%;
      text-align: center;
    }
}
@media only screen and (max-width: 500px){
  
    .writeus {
      left: 14%;
        }
        .writeus::before {
          right: 67%;
              }
.footer__credentials1 p {
  left: 38%;
}
.callus::before {
  left: 13%;
}
}
@media only screen and (max-width: 400px){
  .writeus {
    left: 15%;
      }
  .writeus::before {
    width: 28%;
    right: 66%;
        }
  .footer2 p {
    width: 100%;
    text-align: center;
  }
  .callus {
    width: 120%;
}
  .callus::before {
    left: 10%;
}
.footer__credentials1 p {
  left: 34%;
}
form{
  width: 109%;
}
.nf-form-layout > form:nth-child(1) {
  width: 105%;
}
.nf-form-content {
  width: 85%;
  }
  #nf-field-4 {
    margin-left: 65px;
}
}
@media only screen and (max-width: 320px){
.footer__credentials1 p {
    left: 30%;
}

.writeus::before {
  right: 66%;
}
.callus {
  width: 100%;
  width: 115%;
  }
.callus::before {
 left: 0;
    }  
}