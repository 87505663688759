header {
    background-color: $pink;
    height: 120px;
}

.relative {
    position: relative;
    display: flex;
    flex-flow: row;

}

.row {
    width: 100%;
}

.zabiegi .row {
    width: auto;
}

.nawigacja {
    right: -3%;
}

section {
    padding-bottom: 0px;

}

.navlogo {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    z-index: 9;
}

.navbar {
    padding-top: 25px;
    margin-bottom: 0;
    color: white;
    text-transform: uppercase;
}

.contact {
    padding-right: 15px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-right: 0px;
    padding-top: 25px;
    color: $white;

}

.telefon {
    margin-top: 16px;

}

.email {
    margin-top: 16px;
}

.fb {
    margin-top: 16px;
}

.telefon a:before {
    content: "";
    float: left;
    width: 50px;
    height: 18px;
    margin: 0 5px 0 0;
    background: url(../img/1_03.png) no-repeat 0 0;
    background-position: 16px -183px;
}

.email a:before {
    content: "";
    float: left;
    width: 50px;
    height: 18px;
    margin: 0 5px 0 0;
    background: url(../img/1_03.png) no-repeat 0 0;
    background-position: 16px -134px;

}

.fb {
    content: "";
    float: left;
    width: 50px;
    height: 18px;
    margin: 15px 5px 0 0;
    background: url(../img/1_03.png) no-repeat 0 0;
    background-position: 16px -81px;
}

.rezerwacja-gora-res {
    display: none;
}

.rezerwacja-gora {
    position: relative;
    width: 128px;
    margin-top: 18px;
    text-transform: uppercase;
    text-align: right;
}

.rezerwacja-gora::before {
    content: '';
    position: absolute;
    float: left;
    left: 0;
    width: 15%;
    height: 35px;
    background: url(../img/1_003.png) no-repeat 0 0;
    background-position-x: 0px;
    background-position-y: 0px;
    background-position-x: 0px;
    background-position-y: 0px;
    background-position: 0px 2px;
}

@media (min-width: 1201px) {
    .container {
        max-width: 1170px;
    }

    .logo2 {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .logo2 {
        display: none;
    }

    .contact {
        width: 100%;
    }

    .nawigacja {
        left: 0%;
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background-color: $white;
    }

    .navbar .menu-item-45 {
        width: 70px;
    }

}

@media only screen and (max-width: 991px) {
    .logo2 {
        display: block;
        display: relative;
    }

    .logo {
        display: none;
    }

    .container {
        padding: 0;
        margin: 0;
        max-width: 100%;
    }

    header {
        height: 173px;
    }

    .szerokosc {
        width: 106%;
    }

    .navlogo2 {
        margin: 0;
        background: white;
        width: 100%;
        text-align: center;
    }

    .nawigacja {
        right: 0%;
    }

    .contact {
        width: 100%;
        text-align: center;
        flex-flow: column;
        padding-top: 0px;
    }

    .telefon {
        position: relative;
        width: 100%;
        left: 0;
        text-align: center;
    }

    .telefon a {
        position: relative;
    }

    .telefon a:before {
        position: absolute;
        left: -50px;
    }

    .email {
        position: relative;
        width: 106%;
        left: 0;
        display: block;
        text-align: center;
        display: block;
    }

    .email a {
        position: relative;
    }

    .email a:before {
        position: absolute;
        width: 100%;
        left: -50px;
    }

    .facebookikona {
        width: 100%;
        display: block;
        text-align: center;
        position: absolute;
        left: 56%;
        bottom: 72%;
    }

    .fb {
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
    }

    .navbar-brand .navlogo1 {
        display: relative;

    }

    .navlogo {
        position: relative;
        left: 37%;
    }

    .navbar-toggler {
        padding: 0;
    }

    .navbar .menu-item-45 {
        width: 100%;
    }

    .navbar-collapse {
        background-color: rgba(187, 53, 142, 0.8);
        padding-top: 10px;
    }

    .navbar {
        border-radius: solid;
        width: 100%;
        height: 55px;
        background: #bb358e;
        z-index: 3;
        text-align: center;
        top: 0%;
    }

    .navbar-toggler:focus {
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        box-shadow: none !important;
    }

    .navbar-nav a {
        z-index: 4;
    }

    .obrazektop {
        position: relative;
        z-index: 1;
    }

    .rezerwacja-gora-res::before {
        content: '';
        position: absolute;
        float: left;
        left: 0;
        width: 15%;
        height: 35px;
        background: url(../img/1_003.png) no-repeat 0 0;
        background-position-x: 0px;
        background-position-y: 0px;
        background-position-x: 0px;
        background-position-y: 0px;
        background-position: 0px 2px;
    }

    .rezerwacja-gora-res {
        display: block;
        margin-top: 11px;
        position: relative;
        width: 128px;
        text-transform: uppercase;
        text-align: right;
        left: 43%;
    }

    .rezerwacja-gora {
        display: none;
    }


}

@media only screen and (max-width: 991px) {
    .hamburger {
        position: absolute;
        right: 30px;
    }

    /* Element | http://cattleya.local/cennik/ */

    body>section:nth-child(2) {
        margin-top: 100px;
    }

    /* style.css | http://cattleya.local/wp-content/themes/millenium-studio-theme/assets/css/style.css */

    @media only screen and (max-width: 991px) {
        .email a::before {
            /* width: 100%; */
        }
    }

    .email a::before {
        left: 15px;
    }

    .facebookikona {
        width: 31px;
    }

    .fb {
        width: 40px;
    }

    .fb {
        background-position: -9px -81px;
    }

    .fb {
        margin: 10px auto 0;
    }

}

@media only screen and (max-width: 768px) {
    .facebookikona {
        left: 58%;
    }
}

@media only screen and (max-width: 600px) {
    .facebookikona {
        left: 60%;
    }
}

@media only screen and (max-width: 320px) {}