h1{
    color:$pink; 
}
h3{
    text-transform: uppercase;
    color:$pink;
    text-align: center;
}
.box {
width: 313px;
margin: 15px 35px;
float: left;}
.tlo
{
    width: 313px;
    height: 152px;
    &:hover{
        background-position: bottom;
    }
   
            }
@media only screen and (max-width: 1200px) {    
    .oferty{
        display: flex;
        flex-flow: row;
        justify-content: center;
        flex-wrap: wrap;
    }    
}
@media only screen and (max-width: 768px) {
    .oferty{
        display: flex;
        flex-flow: column;
        align-items: center;
    }    
    .box{
        margin: 0;
    }
}

@media only screen and (max-width: 600px) {
    .oferty{
        display: flex;
        flex-flow: column;
        align-items: center;
    }    
    .box{
        margin: 0;
    }
}
@media only screen and (max-width: 320px) {    
.box{
    text-align: center;
    margin: 0;
}
}