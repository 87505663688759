.galeria {
    width:100%;
    margin:0;
    padding:0;
    overflow: hidden;
    padding-bottom: 5%;
}
h1{
    padding-top: 3%;
    color: $pink;
}
.galeria img{
    width: 200px;
    height: 150px;
}
.galeria ul{
    list-style-type: none;
    background: none;
}
.galeria li{
    float: left;
    margin: 12px;
}
a{
    text-decoration: none;
    outline: none;
} 
@media only screen and (max-width: 1200px) { 
    .galeria ul{
        display: flex;
        flex-flow: row;
        justify-content: center;
        flex-wrap: wrap;
    }  
}
@media only screen and (max-width: 992px) { 

}
@media only screen and (max-width: 768px) { 

}
@media only screen and (max-width: 600px) { 
    .galeria ul{
        display: flex;
        flex-flow: row;
        justify-content: center;
        flex-wrap: wrap;
    }  

}
@media only screen and (max-width: 320px) { 
    .galeria ul{
        display: flex;
        flex-flow: column;
        align-items: center;
    }  


}