.onas {
    padding: 0;
    padding-top: 5%;
}

.onas h1 {
    padding-top: 3%;
    text-align: start;
    color: $pink;
    text-transform: uppercase;
}

.obrazekonas {
    padding: 0;
    display: flex;
    flex-flow: row;
}

.opisobrazka {
    display: flex;
    flex-flow: row;
}

.obrazekonas img {
    width: 100%;
}

.obrazekonas .p {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    text-align: justify;
}

.stopkaopis {
    padding: 0;
    margin: 0;
    left: 59%;
    padding-top: 10%;
    padding-bottom: 10%;
    text-align: justify;
}

.stopkaopis p {
    text-align: end;
}

@media only screen and (max-width: 768px) {
    .obrazekonas {
        flex-flow: column;
        align-items: center;
    }
    .onas h1 {
        text-align: center;
    }
    .obrazekonas img {
        width: 100%;
    }
}