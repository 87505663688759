.navbar-toggler {
  outline: none;
  padding: 7px 3px 5px 3px;
  margin-right: -4px;
  border: 1px solid transparent !important;
  &:focus {
    box-shadow: $shadow-short-light;
  }
}

.navbar {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 
  12px;
}

.navbar-nav {
  margin: 0;
  margin-right: 10px;
  a {
    display: block;
    padding: 10px;
    transition: $transition;
    &:hover {
      color: $grey-mid;
    }
  }
}

