.tytultabeli {
    text-align: center;
    color: $white;
}

table.tytultacen {
    background-color: $pink;
}

td.tytultabelicen {
    width: 100%;
    background-color: $pink;
}

.border {
    border: none;
}

.zabiegi {
    background-color: #e7c9e5;
    text-align: left;
}

.ceny {
    background-color: #e7c9e5;
    text-align: center;
    text-transform: uppercase;

}

@media only screen and (max-width: 1200px) {
    .tytul h3 {
        text-align: center;
        margin-top: 5%;
        width: 100%;
    }

    td.tytultabelicen .tytultabelidrugi {
        text-align: center;
        margin-top: 5%;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    table {
        font-size: 12px;
        line-height: 18px;
    }
}

@media only screen and (max-width: 600px) {
    .tytul h3 {
        text-align: center;
        margin-top: 5%;
        width: 100%;
    }

    td.tytultabelicen .tytultabelidrugi {
        text-align: center;
        margin-top: 5%;
        width: 100%;
    }

}

@media only screen and (max-width: 600px) {
    .tytul h3 {
        text-align: center;
        margin-top: 5%;
    }

    td.tytultabelicen .tytultabelidrugi {
        text-align: center;
        margin-top: 5%;
        width: 100%;
    }

}

@media only screen and (max-width: 320px) {
    .tytul h3 {
        text-align: center;
        margin-top: 5%;
    }

    td.tytultabelicen .tytultabelidrugi {
        text-align: center;
        margin-top: 5%;
    }
}