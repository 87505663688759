/* Colors */
$black: #000;
$white: #fff;
$pink: #bb358e;
$pinkopacyty: rgba(187,53,142,0.7);
$whiteopacyty: rgba(255,255,255,0.7);
$blackpink: #620643;
// Grey hues
$grey: #cacaca;
$grey-mid: #838484;
$grey-dark: #404040;

/* Shadow presets */
$shadow-outline:    0px 2px 5px rgba(0, 0, 0, 0.3);
$shadow-short-light:    0px 5px 10px rgba(0, 0, 0, 0.2);
$shadow-short-mid:     0px 5px 10px rgba(0, 0, 0, 0.4);

/* Font used across the document */
$glob-font: "Roboto", sans-serif;

/* Transition time for news list */
$transition: all 0.2s ease-in-out;
