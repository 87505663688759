/* style.css | http://cattleya.local/wp-content/themes/millenium-studio-theme/assets/css/style.css */

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 991px) {


    .contact {
        padding-bottom: 0;
        background-color: #bb358e;
        justify-content: center;
        align-items: center;
    }

    .telefon {
        left: unset !important;
    }

    .rezerwacja-gora-res {
        width: unset !important;
        left: unset !important;
        padding-left: 25px;
    }

    .email {
        width: unset !important;
        width: 100%;
        padding-left: 30px;
    }


    .fb {
        display: inline-block;

        /* width: unset !important; */
        display: block;
        width: 32px !important;
        background-position: 0px -81px;
    }


    /* Element | http://cattleya.local/kontakt/ */

    .facebookikona {
        bottom: 5px;
        left: -10px;
        position: relative;
    }

}

.telefon a::before {
    /* float: left; */
    padding-left: 40px;
    display: block;
}

.email a::before {
    /* float: left; */
    left: -59px;
}