.aktualnosci{
    width:100%;
    margin-top: 2%;
}
h1{
    text-align: start;
    color: $pink;
    text-transform: uppercase;
}
.article{
    border-bottom-color: black;
}
.promocje{
    max-width: 100%;
    left: 25%;
    border-bottom: 1px solid #C9C9C9;
    padding-bottom: 10px;
}
.promocje:last-child(){
    border-bottom: none;
}
.promocje img{
    margin-top: 5%;
    margin-bottom: 0%;
    width: 100%;
    }
.sekcjakrio{
    max-width: 100%;
    left: 25%;
}
.sekcjakrio img{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
}
.tittle{
    text-transform: uppercase;
    font-weight: bold;
    position: absolute;
    left: 0;
    margin-left: 0;
    top: -1%;
    text-align: left;
}
table{
    background-color: #FFF2FC;
}
td{
    padding: 5px;
}
.promocje h3{
   text-transform: uppercase;
   padding-left: 2%;
}
.promocje ul{
    list-style-position: inside;
    padding-left: 20px;
    color: $pink;
}
.promocje li{
    list-style-position: none;
    text-align: left;
    padding-left: 20px;
}
@media only screen and (max-width: 1200px) {
    .aktualnosci h1{
        text-align: left;
    }
.tytul h3{
    text-align: left;
    padding-left: 2%;
    display:block;
}
.promocje{
    left: 0;
    margin-left: 8%;
}
.promocje img {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 5%;
}
}
@media only screen and (max-width: 991px) {
    .aktualnosci h1{
        text-align: center;
    }
    .tytul h3{
        text-align: center;
        margin-top: 5%;
        display:block;
    }
    .promocje img {
        text-align: center;
        width: 100%;
        margin-top: 10%;
        margin-bottom: 5%;
    }
}
@media only screen and (max-width: 768px) {
    .aktualnosci h1{
        text-align: center;
    }
    .tytul h3{
        text-align: center;
        margin-top: 5%;
    }
    .promocje img {
        text-align: center;
        width: 100%;
        margin-top: 10%;
        margin-bottom: 5%;
    }
}

@media only screen and (max-width: 600px) {
    .aktualnosci h1{
        text-align: center;
    }
    .tytul h3{
        text-align: center;
        margin-top: 5%;
    }
    .promocje img {
        text-align: center;
        margin-top: 20%;
        margin-bottom: 5%;
    }
}


@media only screen and (max-width: 320px) {
    .aktualnosci h1{
        text-align: center;
    }
    .tytul h3{
        text-align: center;
        margin-top: 5%;
    }
    .promocje img {
        text-align: center;
        margin-top: 20%;
        margin-bottom: 5%;
    }

}