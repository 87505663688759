// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
html {
	overflow-x: hidden;
}
body {
	background: #fff;
	overflow-x: hidden;
}

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.Montserrat{
	font-family: "Montserrat";
}

*:focus {
	outline: 1px solid $black;
}

@media screen and (-ms-high-contrast: white-on-black) {
  *:focus {
		outline: 1px solid $white;
	}
}




