.contentzabiegow {
  top: 22%;
  visibility: hidden;
}

.tittleoffer h1 {
  text-align: center;
}

.listatytulowzabiegow {
  font-size: 18px;
  margin-left: 0;
  text-align: center;
  a {
    width: 100%;
    display: block;
  }
}

.listatytulowzabiegow h3 {
  text-align: left;
}

.tytulylistyzabiegow {
  list-style: none;
}

.zabiegi_lista {
  .tytulylistyzabiegow {
    display: none;
    &.active {
      display: block;
    }
  }
}

.kategoriauslug {
  background-color: white;
  padding: 1px 0;
  margin: 10px 0px 10px 15px;
  text-align: left;
  border: 1px solid #bb358e;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #bb358e;

    .naglowekzabiegow {
      color: #fff;
    }
  }
}

.listazabiegow {
  background-color: #bb358e;
  border: #bb358e 1px solid;
  margin: 6px 0;
  padding: 10px;
  text-align: center;
  color: white;
  font-family: "Montserrat";
  transition: 0.5s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: #bb358e;
  }
  &.active {
    opacity: 0.7;
    &:hover {
      background-color: #bb358e;
      color: white;
      opacity: 0.7;
    }

    a {
      font-weight: bold;
    }
  }
}
.contentuslug {
  font-family: "Montserrat";
  line-height: 23px;
  letter-spacing: 0px;
}
.contentzabiegow-res {
  display: none;
}

.contentzabiegow ul {
  text-align: left;
}

.contentzabiegow-res ul {
  text-align: left;
}

@media only screen and (min-width: 1200px) {
  .listaofert {
    display: block;
  }

  .contentzabiegow {
    display: block;
  }

  .contentzabiegow-res {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .contentzabiegow-res {
    font-size: 14px;
    display: block;
    text-align: center;
  }
}
@media screen and (max-width: 991px) {
  .mar-mob {
    margin-top: 50px;
  }
  .contentuslug {
    padding: 30px;
  }
}
